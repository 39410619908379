import { useTheme } from "@mui/material";

function InfoIcon(props) {
    return <svg {...props}>
    <path style={{fill: "white"}} d="M 9.984 1.664 C 5.3914 1.664 1.664 5.3914 1.664 9.984 s 3.7274 8.32 8.32 8.32 s 8.32 -3.7274 8.32 -8.32 S 14.5766 1.664 9.984 1.664 z m 0.832 12.48 h -1.664 v -4.992 h 1.664 v 4.992 z m 0 -6.656 h -1.664 V 5.824 h 1.664 v 1.664 z"></path>
    </svg>
}

function ScoreIcon(props) {
    return <svg {...props}>
            <path style={{fill: "white"}} d="M 2.4 5.4 h 2.4 v 6.6 H 2.4 z m 7.2 2.4 h 2.4 v 4.2 h -2.4 z m -3.6 -5.4 h 2.4 v 9.6 h -2.4 z"/>
        </svg>
}

export default function PointsIcon({points, finished}) {
    const str = Math.round(points).toString() + (finished ? "" : "+");

    const length = Math.max(20, 15 + 5 * str.length) + 15;

    const theme = useTheme()

    return <svg width={length} height="20" viewBox={`0 0 ${length} 20`}>
        <rect x="0" y="0" width={length} height="20" fill={points > 0 ? (finished ? theme.palette.primary.main : theme.palette.primary.dark) : "#aaa"} rx={10} />
        <text x={(length - 15)/2} y="11" font-size="10" fontWeight="bold" fill="white" text-anchor="middle" alignment-baseline="middle">{str}</text>

        <ScoreIcon x={length - 20} y="2" />
    </svg>
}