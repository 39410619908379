import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography, CircularProgress } from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';

export function FixMeButton({handleFix}) {
    const [open, setOpen] = React.useState(false);

    return <Button onClick={() => open == false ? setOpen(true) : null}>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <BuildIcon sx={{color: "white", display: "block", mt: 0.5}} fontSize={'small'}/>
                <Typography variant="caption" color="common.white" sx={{fontWeight: "bold", textTransform: "capitalize", display: "block"}}>
                  Fix me
                </Typography>
              </Box>

              <FixMeDialog open={open} handleClose={() => setOpen(false)} handleFix={handleFix}/>
          </Button>
  }

export function FixMeDialog({open, handleClose, handleFix}) {
    const [fixing, setFixing] = React.useState(false);

    return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Fix Me
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Fix Me kan ontbrekende of foutieve resultaten op tennisstats herstellen.
        Het correcte resultaat moet wel zichtbaar zijn op je profiel van tennisvlaanderen!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Sluiten</Button>
      {!fixing ? <Button onClick={async () => {setFixing(true); await handleFix(); handleClose(); setFixing(false)}} autoFocus>
        Fix Me
      </Button> : <CircularProgress />}
    </DialogActions>
  </Dialog>
}