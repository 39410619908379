import { useTheme } from "@mui/material"

export default function StatisticLine({statistic, left, right}) {
    const theme = useTheme();

    const percentage = left + right > 0 ? 100 * left / (left + right) : null;

    return <svg width="100%" height="40px" style={{marginTop: "10px", marginBottom: "10px"}}>
        <text x="50%" y="12" dominant-baseline="auto" text-anchor="middle" fontWeight="bold" fontSize={14} fill={theme.palette.text.primary_bold}>{statistic}</text>
        <ProgressBar x="15%" y="20" width="70%" height="14px" value={percentage} color={theme.palette.primary.main} />

        <text x="14%" y="28px" dominant-baseline="middle" text-anchor="end" fontWeight="bold" fontSize={16} fill={theme.palette.primary.main}>{left}</text>
        <text x="86%" y="28px" dominant-baseline="middle" text-anchor="start" fontWeight="bold" fontSize={16} fill="#a0a0a0">{right}</text>
    </svg>
}

function ProgressBar({x, y, width, height, value, color}) {
    const adjustedValue = value !== null ? value : 50;
    const isDisabled = value === null || value === undefined ? true : false;

    return <svg x={x} y={y} width={width} height={height}>
        <rect x={adjustedValue + 0.25 + "%"} y="0" width={100 - adjustedValue - 0.25 + "%"} height="14px" fill="#e0e0e0" rx={4} />
        <rect x="0%" y="0" width={adjustedValue - 0.25 + "%"} height="14px" fill={color} rx={4} />
        {!isDisabled ? <text x={adjustedValue + (value > 10 ? -2 : 2) + "%"} y="8px" dominant-baseline="middle" text-anchor={value > 10 ? "end" : "start"} fontWeight="bold" fontSize={12} fill={"white"}>{Math.round(value)}%</text> : null}
    </svg>
}