import * as React from 'react';
import {
  AppBar,Box, Toolbar, Typography, Divider, Drawer, List, ListItem, ListItemText, IconButton, ListItemButton
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import MenuIcon from '@mui/icons-material/Menu';
import ArticleIcon from '@mui/icons-material/Article';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoIcon from '@mui/icons-material/Info';

import {Routes, Outlet, Route, useLocation, useNavigate} from 'react-router-dom';

import {LogoWhiteIcon} from 'assets/Icons'
import MainPage from 'pages/main/main'
import ClubPage from 'pages/club/club';
import ContactPage from 'pages/contact/contact';
import FaqPage from 'pages/faq/faq';
import LeaderboardPage from 'pages/leaderboard/leaderboard';
import ExperimentalPage from 'pages/experimental/experimental';
import SpelerPage from 'pages/speler/speler';
import AboutPage from 'pages/about/about';

function Layout() {
    const location = useLocation();
    let navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const navigateToPage = (page) => {
        if (page !== location.pathname) {
            navigate(page);
            setOpen(false);
        }
    }

    const handleToggle = () => {
        setOpen(!open);
    };


    return <React.Fragment><AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, backgroundColor: "#309cf0" }}
      elevation={0}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleToggle}
                edge="start"
                sx={{
                marginRight: 1
                }}
            >
            <MenuIcon />
          </IconButton>
        <LogoWhiteIcon/>
        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          Tennisstats.be
        </Typography>
        <nav>
          {/*location.pathname == "/club" ? null : <Button href="club" color="inherit">Club zoeken</Button>*/}
          {/*location.pathname == "/" ? null : <Button href="/" color="inherit">Speler zoeken</Button>*/}
        </nav>
      </Toolbar>
    </AppBar>

    <Drawer
        variant="persistent"
        sx={{
          width: '230px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: '230px', boxSizing: 'border-box' },
        }}
        open={open}
      >
        <Toolbar />
        <List sx={{mt: 2}}>
              <ListItem disablePadding sx={{color: location.pathname == "/" || location.pathname.substring(0, 8) == "/speler/" ? "primary.light" : "text.secondary"}}>
                <ListItemButton onClick={()=>{navigateToPage("/")}}>
                    <PersonIcon sx={{mr: 1}} color={"inherit"}/>
                  
                    <ListItemText primary={"Een speler zoeken"} primaryTypographyProps={{ fontWeight: '600', color: "inherit" }} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding sx={{color: location.pathname == "/club" ? "primary.light" : "text.secondary"}}>
                <ListItemButton onClick={()=>{navigateToPage("/club")}}>
                    <GroupIcon sx={{mr: 1}} color={"inherit"}/>
                  <ListItemText primary={"Een club zoeken"} primaryTypographyProps={{ fontWeight: '600', color: "inherit" }} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding sx={{color: location.pathname == "/leaderboard" ? "primary.light" : "text.secondary"}}>
                <ListItemButton onClick={()=>{navigateToPage("/leaderboard")}}>
                    <LeaderboardIcon sx={{mr: 1}} color={"inherit"}/>
                  <ListItemText primary={"Leaderboard"} primaryTypographyProps={{ fontWeight: '600', color: "inherit" }} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding sx={{color: location.pathname == "/contact" ? "primary.light" : "text.secondary"}}>
                <ListItemButton onClick={()=>{navigateToPage("/contact")}}>
                    <ArticleIcon sx={{mr: 1}} color={"inherit"}/>
                  <ListItemText primary={"Contact"} primaryTypographyProps={{ fontWeight: '600', color: "inherit" }} />
                </ListItemButton>
              </ListItem>
              <Divider/>
               <ListItem disablePadding sx={{color: "text.secondary"}}>
                <ListItemButton onClick={()=>{navigateToPage("/about")}}>
                    <InfoIcon sx={{mr: 1}} color={"inherit"}/>
                  <ListItemText primary={"Over Tennisstats"} primaryTypographyProps={{ fontWeight: '600', color: "inherit" }} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding sx={{color: "text.secondary"}}>
                <ListItemButton onClick={()=>{navigateToPage("/faq")}}>
                    <QuestionMarkIcon sx={{mr: 1}} color={"inherit"}/>
                  <ListItemText primary={"FAQ"} primaryTypographyProps={{ fontWeight: '600', color: "inherit" }} />
                </ListItemButton>
              </ListItem>
              <Divider/>
              <ListItem disablePadding sx={{color: "text.secondary"}}>
                <ListItemButton onClick={()=>{window.location.href = "https://padelstats.be"}}>
                    <SportsTennisIcon sx={{mr: 1}} color={"inherit"}/>
                  <ListItemText primary={"Padelstats"} primaryTypographyProps={{ fontWeight: '600', color: "inherit" }} />
                </ListItemButton>
              </ListItem>
              
        </List>
        
      </Drawer>

    <Box component="main" sx={{ flexGrow: 1, minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        
        <Outlet/>
        <Box
            sx={{
            justifyContent: "center",
            display: "flex",
            mb: 2,
            pt: 2,

            }}
        >
            <Typography variant="caption" color="inherit" sx={{textAlign: "center"}}>
            Website gemaakt door Robin & Joost Bruneel <br/>
            Deze website is een privé-initiatief los van Tennis Vlaanderen <br/>
            </Typography>
        </Box>
        
    </Box>

    

    </React.Fragment>
}

export default function Navigation () {

    return (
    
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route index element={<MainPage />} />
            <Route path="club" element={<ClubPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="leaderboard" element={<LeaderboardPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="experimental" element={<ExperimentalPage />} />
            <Route path="speler/*" element={<SpelerPage />} />
        </Route>
    </Routes>

    );
}