import * as React from 'react';
import {
    Typography, Collapse, Stack, Divider, Card, IconButton, Grid,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Popover, Link, Box, CardActionArea,
    TableCell, TableBody, TableRow, Table, TableContainer, TableHead,
} from '@mui/material'

import InfoIcon from '@mui/icons-material/Info';
import {WinIcon, LoseIcon, EmptyIcon} from 'assets/Icons';
import PercentageCard from './PercentageCard';
import {disp_rank} from 'assets/utils';
import { Adsense } from '@ctrl/react-adsense';
import SeriesIcon from 'components/SeriesIcon';

import SwapVertIcon from '@mui/icons-material/SwapVert';
import { FixMeButton } from './FixMe';

import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ParticipantsIcon from 'components/ParticipantsIcon';
import PointsIcon from 'components/PointsIcon';

function getSortedResults(results, sort) {
  if (sort == "Score") {
    return results;
  } else {
    return results.slice().sort((l, r) => r["week"] !== undefined ? r["week"].localeCompare(l["week"]) : 1);
  }
}

export default ({is_single, data, name, handleFix}) => {

    const [open, setOpen] = React.useState(false);
    const [sort, setSort] = React.useState("Score");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const valid_scores_all = data["results"].filter(el => el["score"] > 0);

    const valid_scores = is_single ? valid_scores_all.sort((l, r) => r["score"] - l["score"]).slice(0,6) : valid_scores_all;
    const valid_scores_sum = valid_scores.length == 0 ? 0 : Math.round(100*valid_scores.reduce((partialSum, a) => partialSum + a["score"], 0)/(is_single ? valid_scores.length : 1))/100;

    const show_popover_brackets = is_single;

    const sortedResults = getSortedResults(data["results"], sort);

    return  <>
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"

      sx={{mt: 5}}
    >
      <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div">
        Berekening {is_single? "enkel" : "dubbel"}
      </Typography>
      <IconButton aria-label="Example" color="primary" onClick={handleClickOpen}>
      <InfoIcon/>
      </IconButton>
    </Stack>
    
        


      <Typography variant="body" component="div" color="text.primary_bold">
        Huidig {is_single? "enkelklassement" : "dubbelklassement"}: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary">{disp_rank(data["current_rank"], data["sub_category"])}</Typography>
      </Typography>
      <Typography variant="body" component="div" color="text.primary_bold">
        Voorspelling volgend seizoen: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary">{disp_rank(data["predicted_rank"], data["sub_category"])}</Typography>
      </Typography>
      <Typography variant="body" component="div" color="text.primary_bold">
        Klassementscore: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary" >{data["score"]}</Typography>
            {is_single ? <><Typography display="inline" sx={{fontWeight: "bold"}} color="text.primary_bold"> = </Typography> 
            <Typography display="inline" sx={{fontWeight: "bold"}} color="secondary">{100*data["score_factor"]}%</Typography> 
            <Typography display="inline" sx={{fontWeight: "bold"}} color="text.primary_bold"> &middot; </Typography> 
            <Typography display="inline" sx={{fontWeight: "bold"}} color="primary">{valid_scores_sum}</Typography></> : null}
      </Typography>

      {is_single ? <>
      <Card sx={{mt: 2, border: '1px solid lightgrey'}} elevation={0}>
            <Typography sx={{ p: 2 }} component="div" variant="body2">
              <Typography variant="body2" display="inline" sx={{fontWeight: "bold"}} color="secondary">{100*data["score_factor"]}%</Typography>
              <Typography variant="body2" display="inline" sx={{fontWeight: "bold"}} color="text.primary_bold">: {valid_scores.length}</Typography> {valid_scores.length != 1 ? "resultaten":"resultaat"} met punten 
              
            </Typography>

            {valid_scores.length > 1 ? <> <Divider/>
            <Typography sx={{ p: 2}} component="div" variant="body2">
              <Typography display="inline" sx={{fontWeight: "bold"}} color="primary" component="span" variant="body2">{valid_scores_sum}</Typography>
              <Typography display="inline" sx={{fontWeight: "bold"}} color="text.primary_bold" variant="body2"> = {show_popover_brackets ? "( " : ""}</Typography>

              {valid_scores.map((el, index) => {
                return <React.Fragment key={el["title"] + el["subtitle"] + el["score"]}>
                    <Typography display="inline" sx={{fontWeight: "bold"}} variant="body2" color="primary">{el["score"]}</Typography>
                    {index < valid_scores.length -1 ? <Typography variant="body2" display="inline" sx={{fontWeight: "bold"}} color="text.primary_bold"> + </Typography> : null}
                </React.Fragment>
              })}
              
              {show_popover_brackets ? <Typography variant="body2" display="inline" sx={{fontWeight: "bold"}} color="text.primary_bold"> ) &frasl; {valid_scores.length}</Typography> : null}
            </Typography></> : null}

            {data["notes"].map(note => <React.Fragment key={note}> <Divider/><Typography sx={{ p: 2 }} component="div" variant="body2" dangerouslySetInnerHTML={{__html: note}}></Typography></React.Fragment>)}
    </Card>

    <Typography sx={{ mt: 2 }} component="div" color="text.primary_bold">
      De  <Typography display="inline" sx={{fontWeight: "bold"}} color="primary" component="span">klassementscore</Typography> wordt berekend als het gemiddelde van de zes beste enkelresultaten. 
      Indien minder dan zes wedstrijden gespeeld werden, wordt een <Typography display="inline" sx={{fontWeight: "bold"}} color="secondary" component="span">gewichtsfactor</Typography> toegepast.
      Spelers worden hierna gesorteerd op klassementscore om vervolgens een klassement toegekend te krijgen via de klassementspiramide.<br/><br/>
      De grenzen van de klassementspiramide veranderen doorheen het seizoen waardoor het voorspelde klassement pas op het einde van het seizoen finaal is.
    </Typography></> : <>
    
    {valid_scores.length > 1 ? <Card sx={{mt: 2, border: '1px solid lightgrey'}} elevation={0}>
            <Typography sx={{ p: 2}} component="div" variant="body2">
              <Typography display="inline" sx={{fontWeight: "bold"}} variant="body2" color="primary" component="span">{valid_scores_sum}</Typography>
              <Typography display="inline" sx={{fontWeight: "bold"}} variant="body2" color="text.primary_bold"> = {show_popover_brackets ? "( " : ""}</Typography>

              {valid_scores.map((el, index) => {
                return <React.Fragment key={el["title"] + el["subtitle"] + el["score"]}>
                    <Typography variant="body2" display="inline" sx={{fontWeight: "bold"}} color="primary">{el["score"]}</Typography>
                    {index < valid_scores.length -1 ? <Typography display="inline" sx={{fontWeight: "bold"}} color="text.primary_bold"> + </Typography> : null}
                </React.Fragment>
              })}
              
              {show_popover_brackets ? <Typography variant="body2" display="inline" sx={{fontWeight: "bold"}} color="text.primary_bold"> ) &frasl; {valid_scores.length}</Typography> : null}
            </Typography>

            {data["notes"].map(note => <> <Divider/><Typography sx={{ p: 2 }} component="div" variant="body2" dangerouslySetInnerHTML={{__html: note}}></Typography></>)}
    </Card> : null}

    <Typography sx={{ mt: 2 }} component="div" color="text.primary_bold">
      De  <Typography display="inline" sx={{fontWeight: "bold"}} color="primary" component="span">klassementscore</Typography> wordt berekend als de som van alle behaalde dubbelresultaten. 
      Een speler komt in aanmerking om één klassement te stijgen indien de klassementscore groter is dan
            <Typography color="text.primary_bold" style={{fontWeight: "bold"}} display="inline"> 4 &#xb7; </Typography>
            <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">NR</Typography>
            <Typography color="text.primary_bold" style={{fontWeight: "bold"}} display="inline"> + </Typography>
            <Typography color="primary" style={{fontWeight: "bold"}} display="inline"> HK </Typography>
            
            of vier keer het aantal deelgenomen reeksen met minimaal één winst (<Typography color="secondary" style={{fontWeight: "bold"}} display="inline">NR</Typography>) 
            plus het huidige dubbelklassement van de speler (<Typography color="primary" style={{fontWeight: "bold"}} display="inline">HK</Typography>).<br/><br/>

            Een speler komt in aanmerking om twee klassementen te stijgen indien de klassementscore groter is dan
            <Typography color="text.primary_bold" style={{fontWeight: "bold"}} display="inline"> 7 &#xb7; </Typography>
            <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">NR</Typography>
            <Typography color="text.primary_bold" style={{fontWeight: "bold"}} display="inline"> + </Typography>
            <Typography color="primary" style={{fontWeight: "bold"}} display="inline"> VK </Typography>
            
            of zeven keer het aantal deelgenomen reeksen met minimaal één winst (<Typography color="secondary" style={{fontWeight: "bold"}} display="inline">NR</Typography>) 
            plus het eerstvolgende dubbelklassement van de speler (<Typography color="primary" style={{fontWeight: "bold"}} display="inline">VK</Typography>).
    </Typography>
    
    </>}
      
    

    {/*<Box sx={{marginTop: '30px', marginBottom: '30px'}}>
            <Adsense
              client="ca-pub-9305958838449958"
              slot="4567492781"
              style={{ display: 'block'}}
              layoutKey='-e5+6h-32-8b+ql'
              layout="in-article"
              format="fluid"
            />
            </Box>*/}

    
    <Card sx={{borderRadius: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, mt: 2}} elevation={0}>

       <Grid sx={{pl: 2, backgroundColor: "primary.main", height: 60}}  container alignItems="center" justifyContent="space-between">
              
              <Stack>
            <Typography variant="body" color="common.white" sx={{fontWeight: "bold"}}>
              Resultaten
            </Typography>
            <Typography variant='caption' color="common.white">
                {name} ({disp_rank(data["current_rank"], data["sub_category"])})
              </Typography>
            </Stack>

            <Box>
            <SortButton sort={sort} setSort={setSort}/>
            <FixMeButton handleFix={handleFix}/>
            </Box>
         
        </Grid>
    </Card>
    <Card sx={{borderRadius: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0, border: '1px solid lightgrey', borderTopWidth: 0}} elevation={0}>

        {data["results"].length == 0 ? <Typography component="div" sx={{backgroundColor: "background.paper", padding: 2}}>Geen resultaten gevonden</Typography> : null}

        {sortedResults.map((el, index) => <ResultLine key={el["title"] + el["subtitle"] + el["score"] + el["series"]} data={el} index={index} />)}

        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Berekening enkelklassement</DialogTitle>
            <DialogContent>
            {is_single ? <DialogContentText>
                Het enkelklassement wordt berekend op basis van tennisresultaten die in de afgelopen 12 maanden behaald werden. 
                Aan iedere speler wordt een klassementscore toegekend uitgaande van de zes beste resultaten.
                Aan de hand van deze score kan het klassement van volgend seizoen berekend worden.
                <br/><br/>
                Ieder enkeltornooi waarbij meer dan 1 match gespeeld werd, wordt als een geldig resultaat beschouwd. 
                Aan dit tornooi wordt een score toegekend op basis van de bereikte ronde, de punten van de tegenstanders en het aantal gespeelde wedstrijden.
                Ook de drie interclubwedstrijden tellen mee als één resultaat. In dit geval worden punten toegekend bij winst op basis van het klassement van de tegenstander.
                <br/><br/>
                Indien een speler minder dan 6 geldige resultaten bezit, dan wordt de klassementscore gereduceerd met volgende factor: <br/><br/>

                <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">80%</Typography>: bij 1 geldig resultaat<br/>
                <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">84%</Typography>: bij 2 geldig resultaten<br/>
                <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">88%</Typography>: bij 3 geldig resultaten<br/>
                <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">92%</Typography>: bij 4 geldig resultaten<br/>
                <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">96%</Typography>: bij 5 geldig resultaten<br/>
                <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">100%</Typography>: vanaf 6 geldig resultaten<br/>

                <br/>

                De berekening hier is een voorspelling of benadering en is niet 100% correct. Uw werkelijk klassement wordt enkel en alleen bepaald door Tennis Vlaanderen en niet door ons.
                Voor meer informatie over het klassement kan men <Link underline="none" sx={{fontWeight: "bold"}} href="https://www.tennisenpadelvlaanderen.be/tennis/klassementen" color="primary">hier</Link> terecht.

            </DialogContentText> : 
            <DialogContentText>
            Het dubbelklassement wordt berekend op basis van tennisresultaten die in de afgelopen 12 maanden behaald werden. 
            Iedere speler die minstens vier dubbelwedstrijden in dat jaar gespeeld heeft, komt in aanmerking voor de klassementberekening. 
            Het dubbelklassement kan echter nooit lager zijn dan het enkelklassement.
            Dubbelwedstrijden die in interclub gespeeld werden, worden niet in rekening gebracht.
            <br/><br/>
            Aan iedere gespeelde reeks wordt een score toegekend op basis van de bereikte ronde. 
            De som van al deze scores bepalen het dubbelklassement van het volgende seizoen. <br/><br/>
            Een speler komt in aanmerking om een klassement te dalen indien de behaalde klassementscore lager is dan het totaal aantal deelgenomen reeksen.
            <br/><br/>
            Een speler komt in aanmerking om één klassement te stijgen indien de klassementscore groter is dan
            <Typography color="text.primary_bold" style={{fontWeight: "bold"}} display="inline"> 4 &#xb7; </Typography>
            <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">NR</Typography>
            <Typography color="text.primary_bold" style={{fontWeight: "bold"}} display="inline"> + </Typography>
            <Typography color="primary" style={{fontWeight: "bold"}} display="inline"> HK </Typography>
            
            of vier keer het aantal deelgenomen reeksen met minimaal één winst (<Typography color="secondary" style={{fontWeight: "bold"}} display="inline">NR</Typography>) 
            plus het huidige dubbelklassement van de speler (<Typography color="primary" style={{fontWeight: "bold"}} display="inline">HK</Typography>).<br/><br/>

            Een speler komt in aanmerking om twee klassementen te stijgen indien de klassementscore groter is dan
            <Typography color="text.primary_bold" style={{fontWeight: "bold"}} display="inline"> 7 &#xb7; </Typography>
            <Typography color="secondary" style={{fontWeight: "bold"}} display="inline">NR</Typography>
            <Typography color="text.primary_bold" style={{fontWeight: "bold"}} display="inline"> + </Typography>
            <Typography color="primary" style={{fontWeight: "bold"}} display="inline"> VK </Typography>
            
            of zeven keer het aantal deelgenomen reeksen met minimaal één winst (<Typography color="secondary" style={{fontWeight: "bold"}} display="inline">NR</Typography>) 
            plus het eerstvolgende dubbelklassement van de speler (<Typography color="primary" style={{fontWeight: "bold"}} display="inline">VK</Typography>).<br/><br/>


            De berekening hier is een voorspelling of benadering en is niet 100% correct. Uw werkelijk klassement wordt enkel en alleen bepaald door Tennis Vlaanderen en niet door ons.
            Voor meer informatie over het klassement kan men <Link underline="none" sx={{fontWeight: "bold"}} href="https://www.tennisenpadelvlaanderen.be/tennis/klassementen" color="primary">hier</Link> terecht.
            
            </DialogContentText>
            }
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
        
        

      </Card>
  </>
}


const CustomButton = styled(Button)({
  width: '100%',
  boxShadow: 'none',
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
  },
});

const ResultLine = ({data, index}) => {

    const [open, setOpen] = React.useState(false);

    const theme = useTheme();
    return <>
    {index > 0? <Divider/> : null}

      <CustomButton
          onClick={()=>{setOpen(!open)}}
          disableRipple
          component="div"
          sx={{p: 0}}
        >
        <Grid container spacing={0} >
        <Grid item xs={12} sx={{py: 1.5, px: 2}}>

              
              <Typography variant="body2" component="div" sx={{pr: 0.5, fontWeight: "bold", fontSize: 14}} color="text.primary_bold">
                {data["title"]}
              </Typography>

            
            
            <Typography variant="caption" component="div"  color="text.secondary" sx={{fontSize: 12}}>
              {data.participants ? [
                <ParticipantsIcon participants={data.participants} color={theme.palette.text.secondary}/>,
                <Typography variant="caption" component="span"  color="text.secondary" sx={{fontSize: 12, marginRight: "3px", marginLeft: "3px"}}>
                  {"\u2022"}
                </Typography>
              ]: null}
              
              {data["subtitle"]}
            </Typography>

            <div style={{display: "flex", alignItems: "center", marginTop: "5px"}}>
            {data.series? [
              <SeriesIcon series={data.series.substring(1).toUpperCase()} singles={data.series.substring(0,1) == "e"}/>,
              <Divider orientation={"vertical"} flexItem sx={{my: 0.2}} style={{marginRight: 5, marginLeft: 0}}/>
            ] : null}
            <PointsIcon points={data["score"]} finished={data["finished"]}/>

            <Divider orientation={"vertical"} flexItem sx={{my: 0.2}} style={{marginRight: 5, marginLeft: 5}}/>

            {"WL" in data ? data["WL"].map((el, index) => el == "W" ? <WinIcon key={el + index}/> : (el == "L" ? <LoseIcon key={el + index}/> : <EmptyIcon key={el+index}/>)) : null}
              
            {/*<InfoIcon style={{marginLeft: "2px", color: "#ccc"}}/>*/}
            </div>

            
        </Grid>

        </Grid>

        </CustomButton>
        
        <Collapse in={open} unmountOnExit>
          <Box sx={{display: "flex", justifyContent: "center", flexDirection: "column", px: 4}}>
            {"matches" in data ? <MatchResults matches={data["matches"]} /> : null}

          <TournamentResult data={data}/>

          {data["url"] ? <Button sx={{display: "flex", alignSelf: "center", mb: 2, mt: -1}} onClick={() => window.open(data["url"])}>Naar tabel</Button> : null}
          </Box>
        </Collapse>
        

    
    
    
  </>
}

const CustomBox = styled(Box)({
  border: "1px solid lightgray",
  borderRadius: 5,
});

function Literal({literal}) {
  const theme = useTheme()
  return [
    <span style={{color: theme.palette.secondary.main}}>{literal[0]}</span>,
    <span style={{color: theme.palette.text.secondary, marginLeft: "5px", marginRight: "5px"}}>{"\u0078"}</span>,
    <span style={{color: theme.palette.primary.main}}>{literal[1]}</span>
  ]
}

const TournamentResult = ({data}) => {
  const theme = useTheme()
  return <TableContainer component={CustomBox} sx={{mt: 1, mb: 2}}>
  <Table sx={{p: 0}} size="small" aria-label="a dense table">
    <TableHead>
      <TableRow>
        <TableCell sx={{p: 1}}></TableCell>
        <TableCell align="right" sx={{fontWeight: "bold", color: "text.primary_bold"}}>Punten</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>

        {data["subscores"].map((el, index) => {
                return <TableRow 
                        key={el["title"] + el["score"]}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell sx={{p: 1, color: "text.primary_bold"}}>
                          <Typography variant="body" component="div"  color="text.primary">
                          {el["title"]}
                          </Typography>
          </TableCell>
        <TableCell align="right" sx={{fontWeight: "bold", color: "primary.main"}}>
          <Typography variant="body" component="div"  color="primary.main" sx={{fontWeight: "bold"}}>
              {el["literal"] ? <Literal literal={el["literal"]}/> : el["score"]}
          </Typography>

        </TableCell>
                    </TableRow>
            })}
      
    </TableBody>
  </Table>
</TableContainer>
}

const MatchResults = ({matches}) => {
  return <CustomBox sx={{mt: 2}}>
    {matches.map(el => <MatchResult key={el.round} match={el}/>)}
  </CustomBox>

}

const MatchResult = ({match}) => {
    const theme = useTheme();

    const num_opponents = match["opponents"].length

    const navigate = useNavigate();

    return <Box p={1} onClick={() => num_opponents == 1 ? navigate('/speler/' + match.opponents[0].user_id) : null} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ borderBottom: "1px solid lightgray" ,'&:last-child': { borderBottom: 0 }, cursor: num_opponents == 1 ? "pointer" : undefined }}>
    <Box display="flex" flexDirection="column" lineHeight={1.4}>
    {match["opponents"].map(opponent => <Typography key={opponent["user_id"]} sx={{cursor: "pointer"}} onClick={() => navigate('/speler/' + opponent.user_id)} variant="caption" fontWeight="bold" color="primary.main">
      {opponent["name"]}{num_opponents > 1 ? <b style={{color: theme.palette.text.primary_bold}}> • {opponent.rank} ptn</b> : null}
    </Typography>)}
    
    <Typography variant="caption" color="text.secondary" lineHeight={1.4}>
      {num_opponents == 1 ? <b style={{color: theme.palette.text.primary_bold}}>{match.opponents[0].rank} ptn • </b> : null}{match.round}
    </Typography>
    </Box>
    <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center">
      <Typography variant="caption" color="text.secondary" fontWeight="bold" px={1} pt='1px'>
        {match.score}
      </Typography>
      {match["did_win"] == 1 ? <WinIcon/> : <LoseIcon/>}
    </Box>
  </Box>
}

function SortButton ({sort, setSort}) {

  const updateSort = () => {
    setSort(sort == "Score" ? "Week" : "Score")
  }

  return <Button onClick={updateSort}>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <SwapVertIcon sx={{color: "white", display: "block", mt: 0.5}} fontSize="small" viewBox="2 2 20 20"/>
      <Typography variant="caption" color="common.white" sx={{fontWeight: "bold", textTransform: "capitalize", display: "block"}}>
        {sort}
      </Typography>
    </Box>

  </Button>
}