import * as React from 'react';
import {
  Popover, Container, Divider, Card, CardContent, Box, Typography
} from '@mui/material'

import UserCard from './components/UserCard';
import CalculationCard from './components/CalculationCard';
import UserSearchCard from './components/UserSearchCard';
import PercentageCard from './components/PercentageCard';
import LeaderboardCard from './components/LeaderboardCard';
import News from 'components/News';
import PageWrapper from 'components/PageWrapper'

import {Adsense} from '@ctrl/react-adsense';
import constants from 'assets/constants'
import {useLocation, useNavigate} from 'react-router-dom';
import {fetchData} from 'assets/utils';
import { useFavorites } from 'assets/favorites';

async function fetchCounter() {
  const data = await fetch(constants["BACKEND"] + "/get_counter");
  const content = await data.json();
  
  return content;
}

export default function MainPage() {
    const [counter, setCounter] = React.useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const firstRender = React.useMemo(()=> {
      fetchCounter().then(result => {setCounter(result);})
    }, []);
  
    return <PageWrapper counter={counter} SearchCard = {()=><UserSearchCard fetchData={(id) => {fetchData(id, null, (result) => navigate("/speler/" + id, {state: {...result, ts: new Date().getTime()}}))}}/>}>

          <News/>

      
      </PageWrapper>
}