import * as React from 'react';
import {
  Container, Link, Card, Box, Typography, Collapse, CardActionArea
} from '@mui/material'

import PageWrapper from 'components/PageWrapper';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function FaqPage() {
    
  
    return <PageWrapper>
            <Box position="relative" sx={{mt: '25px', pb: 2}}>   
            <Card elevation={0} sx={{
                pt: 3, pb: 3.5, px: 3, 
                borderRadius: 10, 
                border:  (theme) => '20px solid ' + theme.palette.primary.main,
                
                position: 'absolute',
                transform: 'translate(0, calc(-100% + 0px))',
                width: "100%"
                }}>
            <Typography variant="h6" fontWeight="bold" color="primary" component="div">
                FAQ
            </Typography>
            

            <Typography variant="body" fontWeight="light" component="div" >
                Veelgestelde vragen
            </Typography>
            </Card>
            </Box>  

            <FAQ title="Hoe wordt de enkelklassementspiramide berekend?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                    1. Elke tennisspeler aangesloten bij Tennis Vlaanderen of AFT ontvangt door het spelen van interclub- of competitietornooien een klassementscore. 
                    Deze score is te vinden op deze website bij de spelergegevens. <br/><br/>
                    2. Alle Belgische spelers die minstens twee officiële wedstrijden gespeeld hebben in de afgelopen twee seizoenen (12 maand) worden toegekend aan hun klassementsgroep (bijv. volwassen mannen, meisjes U13 ... ) en vervolgens naargelang hun klassementscore geordend.<br/><br/>
                    3. Voor iedere klassementsgroep worden grenzen geselecteerd op basis van voorgedefiniëerde percentages die aanduiden hoeveel spelers er mogen zijn met 3 punten, 5 punten, 10 punten ... <br/><br/>
                    4. Geklasseerde spelers die 0 punten behaalden (meer dan twee wedstrijden, maar geen winstwedstrijden) worden in de piramide bij spelers van 1 klassement lager gevoegd, nog steeds rekening houdend met het percentage spelers dat er in die ‘schijf’ toegelaten is. <br/><br/>
                    Op basis van de bovenstaande stappen kan men perfect de piramidegrenzen gaan berekenen. Merk echter op dat de werkelijke klassementen niet altijd overeen komen met je plaats in de piramide.
                    Tennis Vlaanderen legt namelijk vijf constraints op voor iedere speler:
                    <ul>
                    <li>Als je ooit een klassement van hoger dan 3 punten had dan kan je niet onder de 5 punten dalen</li>
                    <li>maximum 1 klassement dalen per jaar</li>
                    <li>1 klassement bij minder dan 3 winstmatchen</li>
                    <li>2 klassementen bij 3 of 4 winstmatchen</li>
                    <li>Onbeperkt stijgen bij 5 of meer winstmatchen en minstens in 2 reeksen een resultaat behaald (waarbij interclub als 1 reeks moet worden gezien)</li>
                    </ul>
                    Deze beperkingen leiden ertoe dat een aantal spelers nog verhuizen van schijf in de piramide en dat de piramide ietwat gaat afwijken van de oorspronkelijke numerus clausus (de percentages) per schijf.

                </Typography>
            </FAQ>

            <FAQ title="Waarom verandert mijn klassementsvoorspelling doorheen het seizoen?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                De klassementscores van de spelers veranderen voortdurend doorheen het seizoen. 
                Aangezien deze scores de bouwstenen van de piramide zijn, zullen deze grenzen continu veranderen.
                Natuurlijk, hoe verder in het seizoen, hoe stabieler de grenzen zullen zijn en dus ook de schatting van je toekomstig klassement.
                </Typography>
            </FAQ>

            <FAQ title="Er ontbreekt een wedstrijd/tornooi in mijn resultaten?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                Indien je denkt dat er data ontbreekt in je overzicht, 
                stuur ons de reeks in een berichtje (via <Link href="mailto:info.tennisstats@gmail.com" underline="none">
                info.tennisstats@gmail.com
                </Link>). We zorgen dat dit zo snel mogelijk in orde komt.
                </Typography>
            </FAQ>

            <FAQ title="Ik heb een wedstrijd in Vlaanderen gespeeld, maar vind mijn naam niet terug in de lijst?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                    Het kan zijn dat je jezelf niet kan vinden in onze zoekfunctie. Indien zo, contacteer ons via <Link href="mailto:info.tennisstats@gmail.com" underline="none">
                info.tennisstats@gmail.com
                </Link> en we zorgen dat je resultaten zo snel mogelijk zichtbaar zijn.
                </Typography>
            </FAQ>

            <FAQ title="Hoe accuraat is de klassementsvoorspelling?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                Voor het dubbelklassement is de berekening vrij voor de hand liggend en zal de berekening zeer accuraat zijn.
                Weet echter dat je dubbelklassement meestijgt met je enkel klassement, het is nog steeds zo dat je dubbelklassement gelijkgesteld wordt aan je enkelklassement indien je enkelklassement hoger uitvalt dan het berekende dubbelklassement. 
                Op deze website tonen wij het berekende dubbelklassement zonder rekening te houden met deze regel. <br/><br/>
                Voor enkelklassementen is onze berekening een benadering van wat TennisVlaanderen en AFT op het einde van het seizoen doen. Hoewel wij de meeste data waarop de berekening gebaseerd is ter beschikking hebben, zijn er toch nog een aantal zaken die ervoor zorgen dat er een afwijking kan zijn:
                <ul>
                    <li>
                    Momenteel nemen wij internationale matchen nog niet mee (ITF, Tennis Europe, ATP en WTA), waardoor de scores van spelers in dit segment niet correct zullen zijn. We plannen wel dit nog toe te voegen. Het effect van deze resultaten op de totale piramide is echter klein voor het gros van de spelers.
                    </li>
                    <li>
                    Er zijn een aantal grijze zones in het opstellen van de piramides, waar wij op dit ogenblik mss een andere invulling geven dan TennisVlaanderen en AFT bij de berekening ervan.
                    </li>
                    <li>
                    TennisVlaanderen en AFT hebben in de voorbij seizoenen nogal wat extra, niet gedocumenteerde, operaties op de piramides uitgevoerd om de resultaten wat aanvaardbaar te krijgen. Dit was grotendeels uit noodzaak door de speel beperkingen tijdens de Covid periode. 
                    </li>
                </ul>
                Elk seizoen analyzeren we de resultaten en doen we aanpassingen om de voorspellingen te verbeteren.
                </Typography>
            </FAQ>

            <FAQ title="Worden wedstrijden in Wallonië meegenomen in de berekening?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                Wij beschikken momenteel over alle enkelwedstrijden georganiseerd door AFT. Deze zal je dus ook terugvinden in je berekening. 
                Merk wel op dat dubbelwedstrijden in Wallonië nog niet in rekening genomen worden.
                </Typography>
            </FAQ>

            <FAQ title="Wat met privacy?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                Wij herverpakken enkel sportresultaten van wedstrijden/tornooien die reeds publiek beschikbaar zijn op het internet.
                De resultaten worden uitsluitend gebruikt met als doeleinde de spelers inzicht te geven in de resultaten van het lopende seizoen en zo goed als mogelijk te begeleiden
                in de complexiteit van de enkel- en dubbelklassement berekening met als extra een voorspelling of schatting van hun klassement voor volgend seizoen.
                Staan je resultaten ongewenst op onze site? Email ons via <Link href="mailto:info.tennisstats@gmail.com" underline="none">info.tennisstats@gmail.com
                </Link> met de vraag verwijderd te worden. Zie ook onze <Link href="about">privacyverklaring</Link> voor meer info.
                </Typography>
            </FAQ>


    </PageWrapper>
}

const FAQ = ({title, children}) => {
    const [open, setOpen] = React.useState(false);



    return <>

    <Box sx={{backgroundColor: "primary.main", borderRadius: 2, mb: 1}}>
        <CardActionArea sx={{flexDirection: "row", display: "flex", py: 1, px: 2, justifyContent: "flex-start"}} onClick={()=>{setOpen(!open)}}>
            <ChevronRightIcon style={{ transform: open ? "rotate(90deg)" : "rotate(0)", transition: "all 0.1s linear" }}/>
            
            <Typography sx={{px: 1}} fontWeight="bold">{title}</Typography>
        </CardActionArea>
    </Box>

    <Collapse in={open}>
        {children}
    </Collapse>

</>}