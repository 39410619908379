import * as React from 'react';
import constants from 'assets/constants'

const allRankings = [1,2,3,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,105,110,115];

export function getAllRankings(rank) {
    return allRankings;
}

export function disp_rank(r, sub_cat) {
    if (sub_cat.length == 1) {
        return r + " ptn";
    } else {
        return get_sub_cat(sub_cat, r) + " (" + r + " ptn" + ")";
    }
}

export function disp_rank_short(r, sub_cat) {
    if (sub_cat.length == 1) {
        return r + " ptn";
    } else {
        return get_sub_cat(sub_cat, r);
    }
}

export function prev_rank(rank, is_adult) {
    if (is_adult) {
        if (rank <= 5) {
            return 3;
        } else {
            return rank - 5;
        }
    } else {
        if (rank < 5) {
            return rank == 1 ? 1 : rank - 1;
        } else if (rank == 5) {
            return 3;
        } else {
            return rank - 5;
        }
    }
}

export function next_rank(rank, is_adult) {
    if (is_adult) {
        if (rank == 3) {
            return 5;
        } else {
            return rank + 5//rank <= 110 ? rank + 5 : rank;
        }
    } else {
        if (rank < 3) {
            return rank + 1;
        } else if (rank == 3) {
            return 5;
        } else {
            return rank + 5//rank <= 25 ? rank + 5 : rank;
        }
    }
}

export function get_sub_cat(sub_cat, rank) {
    var n;

    if (rank == 1)
        n = 8;
    else if (rank == 2)
        n = 7
    else if (rank == 3)
        n = 6
    else
        n = 5-(rank-5)/5

    return sub_cat.substring(1).replace("09", "9") + "." + n
}

export function useFirstRender() {
    const ref = React.useRef(true);
    const firstRender = ref.current;
    ref.current = false;
    return firstRender;
}

export function scrollTo(offset, callback) {
    const fixedOffset = offset.toFixed();
    const onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll)
                callback()
            }
        }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
        top: offset,
        behavior: 'smooth'
    })
}

export async function refreshData (refresh_url, callback, onFail) {
    const req = await fetch(constants["BACKEND"] + refresh_url);
        
    if (req.status == 200) {
        const data = await req.json();
        callback(data);
    } else {
        onFail()
    }
  }

export async function fetchData(userId, season, callback) { // fetch before show?
      const data = await fetch(constants["BACKEND"] + "/get_user_report/" + userId + (season != null ? "?s=" + season : ""));
  
      const content = await data.json();

      callback(content);
  }

let fetchChartPromise = null;
let cd = null;
export function useChartData() {

    const [chartData, setChartData] = React.useState(cd ? cd : {});

    if (cd === null) {
        if (fetchChartPromise === null) {
            fetchChartPromise = fetch(constants["BACKEND"] + "/get_charts").then(response => response.json());
            fetchChartPromise.then(data => cd = data);
        }
        fetchChartPromise.then(data => {
            setChartData(data);
        });
    }
    return chartData;
}

export async function fixData(userId, callback) {
    const data = await fetch(constants["BACKEND"] + "/fixme/" + userId);


    if (data.status == 200) {
        const content = await data.json();
        callback(content);
    }
}

export async function getRecentResults(users) {
    const data = await fetch(constants["BACKEND"] + "/recent_results", {
        method: "POST", 
        body: JSON.stringify({users: users}),
        headers: {
            "Content-Type": "application/json",
        }
    }) 
    if (data.status == 200) {
        return await data.json()
    }
    return null
}

export function stripAccents(s) {
    return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
}