import { Stack, Grid, Typography, useMediaQuery } from "@mui/material"
import StatisticCircle from "components/StatisticCircle"
import StatisticLine from "components/StatisticLine"

export default function Statistics({data}) {

    const matches = useMediaQuery('(min-width:500px)');

    return <Stack flexDirection="column" alignItems="center">
        <Grid container sx={{mb: 1, width: matches ? "80%" : "100%"}} spacing={2}>
            <Grid item xs={6} sm={6} sx={{display: "flex", alignContent: "center", justifyContent: "center"}}>
                <StatisticCircle width="100%" style={{maxWidth: 160}} statistic="Enkels gewonnen" left={data["singles"]["nw"]} right={data["singles"]["nm"] - data["singles"]["nw"]}/>
            </Grid>
            <Grid item xs={6} sm={6} sx={{display: "flex", alignContent: "center", justifyContent: "center"}}>
                <StatisticCircle width="100%" style={{maxWidth: 160}} statistic="Dubbels gewonnen" left={data["doubles"]["nw"]} right={data["doubles"]["nm"] - data["doubles"]["nw"]}/>
            </Grid>
        </Grid> 

            <StatisticLine statistic="Gewonnen enkelspellen" left={data["singles"]["ngw"]} right={data["singles"]["ngp"] - data["singles"]["ngw"]}/>
            <StatisticLine statistic="Gewonnen dubbelspellen" left={data["doubles"]["ngw"]} right={data["doubles"]["ngp"] - data["doubles"]["ngw"]}/>
            <StatisticLine statistic="Gewonnen tiebreaks" left={data["singles"]["ntw"] + data["doubles"]["ntw"]} right={data["singles"]["ntp"] - data["singles"]["ntw"] + data["doubles"]["ntp"] - data["doubles"]["ntw"]}/>
        </Stack>
}