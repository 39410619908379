const leaderboard = [
    {tag: "singles_t", name: "Aantal gespeelde enkeltornooien", name_short: "Enkeltornooien", content: null, lb_type: 0},
    {tag: "singles_s", name: "Enkelklassementscore", name_short: "Enkelscore", content: null, lb_type: 0},
    {tag: "singles_f", name: "Aantal gewonnen enkelfinales", name_short: "Enkelfinales", content: null, lb_type: 0},
    {tag: "doubles_t", name: "Aantal gespeelde dubbeltornooien", name_short: "Dubbeltornooien", content: null, lb_type: 0},
    {tag: "doubles_s", name: "Dubbelklassementscore", name_short: "Dubbelscore", content: null, lb_type: 0},
    {tag: "doubles_f", name: "Aantal gewonnen dubbelfinales", name_short: "Dubbelfinales", content: null, lb_type: 0},
    //{tag: "singles_m", name: "Aantal gespeelde enkelmatchen", name_short: "Enkelmatchen", content: null},
    //{tag: "doubles_m", name: "Aantal gespeelde dubbelmatchen", name_short: "Dubbelmatchen", content: null},
    {tag: "club_fs", name: "Aantal gewonnen enkelfinales door de club", name_short: "Clubfinales enkel", content: null, lb_type: 1},
    {tag: "club_fd", name: "Aantal gewonnen dubbelfinales door de club", name_short: "Clubfinales dubbel", content: null, lb_type: 1},
    {tag: "club_t", name: "Aantal gespeelde tornooien door de club", name_short: "Clubtornooien", content: null, lb_type: 1},
]

const rankFilter = {"3 ptn": 3, "5 ptn": 5, "10 ptn": 10, "15 ptn": 15, "20 ptn": 20, "25 ptn": 25, "30 ptn": 30, "35 ptn": 35, "40 ptn": 40, "45 ptn": 45, 
"50 ptn": 50, "55 ptn": 55, "60 ptn": 60, "65 ptn": 65, "70 ptn": 70, "75 ptn": 75, "80 ptn": 80, "85 ptn": 85, "90 ptn": 90, "95 ptn": 95,
"100 ptn": 100, "105 ptn": 105, "110 ptn": 110}
const pyramidFilter = {"Mannen": "M", "Vrouwen": "V", "Meisjes 13": "V13", "Jongens 13": "M13", "Meisjes 11": "V11", "Jongens 11": "M11", "Jeugd 09": "U09"}



const constants = {
    "BACKEND": "https://tennisstats.be/api",
    //"BACKEND": "http://localhost:5001/api",
    "LEADERBOARD": leaderboard,
    "SHOW_ADS": false,
    "FILTERS": {"RANK": rankFilter, "PYRAMID": pyramidFilter}
}

export default constants;