import { ThemeProvider, createTheme, CssBaseline, ThemeOptions } from "@mui/material";
import {FavoritesProvider} from 'assets/favorites';

import Navigation from "Navigation"

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#42a7f5',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#aa00ff',
    },
    text: {
      primary_bold: 'rgba(0, 0, 0, 0.75)'
    },
    background: {
      default: '#FFFFFF',
    },
  },
};

function App() {

  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <FavoritesProvider>
        <CssBaseline/>
        <Navigation/>
      </FavoritesProvider>
    </ThemeProvider>  
  );
}

export default App;
