import * as React from 'react';
import {
  Container, Link, Card, Box, Typography, Collapse, CardActionArea
} from '@mui/material'

import PageWrapper from 'components/PageWrapper';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function AboutPage() {
    
  
    return <PageWrapper>
            <Box position="relative" sx={{mt: '25px', pb: 2}}>   
            <Card elevation={0} sx={{
                pt: 3, pb: 3.5, px: 3, 
                borderRadius: 10, 
                border:  (theme) => '20px solid ' + theme.palette.primary.main,
                
                position: 'absolute',
                transform: 'translate(0, calc(-100% + 0px))',
                width: "100%"
                }}>
            <Typography variant="h6" fontWeight="bold" color="primary" component="div">
                Over Tennisstats.be
            </Typography>

            <Typography variant="body" fontWeight="light" component="div" >
            </Typography>
            </Card>
            </Box>  

            <FAQ title="Wie is Tennisstats.be?">
                <Typography sx={{color: "text.primary", mb: 3}}>
                Tennisstats.be is een persoonlijk privé initiatief los van Tennis Vlaanderen en een uit de voegen gebarsten hobbyproject van Robin Bruneel en Joost Bruneel.
                Initieel hadden we de berekening geimplementeerd zonder user interface voor leden van onze eigen club, maar al vlug kwam de vraag van andere clubs in de buurt om dit ook voor hen uit te voeren.
                Een veralgemening in de vorm van een app die voor alle clubs werkt leek ons dan ook voor de hand liggend.
                Er zit geen enkel commercieel belang of winstmodel achter en dat willen we graag zo houden.
                </Typography>
            </FAQ>

            <FAQ title="Privacyverklaring">
                <Typography sx={{color: "text.primary", mb: 3}}>
                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>Privacyverklaring Tennisstats.be</Typography><br/><br/>

                Laatst bijgewerkt op 23/08/2023<br/><br/>

                Welkom bij Tennisstats.be. Wij hechten veel waarde aan de bescherming van jouw privacy en willen transparant zijn over de manier waarop wij jouw persoonsgegevens verzamelen, gebruiken, delen en bewaren.<br/><br/>

                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>1. Verzamelde informatie</Typography><br/><br/>

                Wij verzamelen de volgende soorten persoonsgegevens:<br/>
                - Naam<br/>
                - Club<br/>
                - Wedstrijdresultaten<br/>
                - Klassement enkel & dubbel<br/>
                - Voorspeld enkel & dubbel klassement<br/>

                <br/>
                Deze gegevens worden verzameld via publiek beschikbare bronnen op het internet, met uitzondering van het voorspeld klassement dat door ons berekend wordt.<br/><br/>


                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>2. Doeleinden van de gegevensverwerking</Typography><br/><br/>

                Wij verwerken persoonsgegevens voor de volgende doeleinden:

                De gegevens worden uitsluitend gebruikt met als doeleinde het berekenen en presenteren van wedstrijd en prestatie statistieken,
                maar vooral om de gemeenschap van spelers inzicht te geven in de resultaten van het lopende seizoen en zo goed als mogelijk te ondersteunen
                in de complexiteit van de klassementberekening met als extra een voorspelling of schatting van hun klassement voor het volgende seizoen. (gerechtvaardigd belang)<br/><br/>

                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>3. Delen van informatie</Typography><br/><br/>

                Wij delen jouw persoonsgegevens niet met derden, behalve indien vereist door de wet.<br/><br/>

                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>4. Cookies en trackingtechnologieën</Typography><br/><br/>

                Wij maken geen gebruik van cookies of vergelijkbare trackingtechnologieën.<br/><br/>

                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>5. Beveiliging</Typography><br/><br/>

                Wij nemen passende technische en organisatorische maatregelen om jouw persoonsgegevens te beschermen tegen ongeautoriseerde toegang, openbaarmaking, wijziging of vernietiging.<br/><br/>

                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>6. Jouw rechten</Typography><br/><br/>

                Je hebt het recht om jouw persoonsgegevens in te zien, te corrigeren, te verwijderen of over te dragen. Neem contact met ons op via <Link href="mailto:info.tennisstats@gmail.com" underline="none">
                info.tennisstats@gmail.com
                </Link> voor het uitoefenen van deze rechten.<br/><br/>

                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>7. Wijzigingen in deze privacyverklaring</Typography><br/><br/>

                Wij behouden ons het recht voor om deze privacyverklaring op elk moment bij te werken. De bijgewerkte versie zal op de website worden geplaatst met de bijbehorende herzieningsdatum.<br/><br/>

                <Typography sx={{fontWeight: "bold", color: "text.primary_bold", display: "inline"}}>8. Contact</Typography><br/><br/>

                Voor vragen, opmerkingen of verzoeken met betrekking tot deze privacyverklaring, neem contact met ons op via <Link href="mailto:info.tennisstats@gmail.com" underline="none">
                info.tennisstats@gmail.com
                </Link>.
                </Typography>
            </FAQ>


    </PageWrapper>
}

const FAQ = ({title, children}) => {
    const [open, setOpen] = React.useState(false);



    return <>

    <Box sx={{backgroundColor: "primary.main", borderRadius: 2, mb: 1}}>
        <CardActionArea sx={{flexDirection: "row", display: "flex", py: 1, px: 2, justifyContent: "flex-start"}} onClick={()=>{setOpen(!open)}}>
            <ChevronRightIcon style={{ transform: open ? "rotate(90deg)" : "rotate(0)", transition: "all 0.1s linear" }}/>

            <Typography sx={{px: 1}} fontWeight="bold">{title}</Typography>
        </CardActionArea>
    </Box>

    <Collapse in={open}>
        {children}
    </Collapse>

</>}