import * as React from 'react';
import {
  Popover, Container, Divider, Card, CardContent, Box, Typography, CircularProgress, Skeleton
} from '@mui/material'

import { Adsense } from '@ctrl/react-adsense';
import UserCard from '../main/components/UserCard';
import CalculationCard from '../main/components/CalculationCard';
import UserSearchCard from '../main/components/UserSearchCard';
import LeaderboardCard from '../main/components/LeaderboardCard';
import PageWrapper from 'components/PageWrapper'
import {fetchData, fixData, refreshData } from 'assets/utils';
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';

export default function SpelerPage() {
    const location = useLocation();
    const userId = parseInt(useParams()["*"]);
    const [searchParams, setSearchParams] = useSearchParams();

    const valid_state = location.state != null && userId === location.state["user_id"] && new Date().getTime() - location.state["ts"] < 10000;

    const [data, setData] = React.useState(valid_state ? location.state : null);
    const [counter, setCounter] = React.useState(null);
    const fetching = React.useRef(false);
    const refreshing = React.useRef(false);
   
    const navigate = useNavigate();

    const firstRender = React.useEffect(()=> {
      if (valid_state) {
        setData(location.state)
      } else if (fetching.current == false) {
        fetching.current = true
        fetchData(userId, searchParams.get("s"), (result) => {if (result) {setData(result)};fetching.current=false})
      }
    }, [userId, searchParams]);

    React.useEffect(() => {
      if (data != null) {
        setCounter(data["counter"])

        if ("refresh_url" in data && refreshing.current == false) {
          refreshing.current = true
          refreshData(data["refresh_url"], (result) => {setData(result);refreshing.current=false}, () => {refreshing.current = false});
        }
      }
    }, [data]);
  
    return <><PageWrapper counter={counter} setCounter={setCounter} SearchCard = {()=><UserSearchCard fetchData={(id) => {fetchData(id, null, (result) => navigate("/speler/" + id, {state: {...result, ts: new Date().getTime()}}))}}/>}>
        
            <UserCard data={data} canSelectSeason={() => !fetching.current && !refreshing.current} sx={{mt: -15}} />

            {data != null ? <>
            

                <LeaderboardCard data={data} />

                <CalculationCard is_single={true} name={data["name"]} data={data["singles"]} handleFix={() => fixData(data["user_id"], setData)}/>
                <CalculationCard is_single={false} name={data["name"]} data={data["doubles"]} handleFix={() => fixData(data["user_id"], setData)}/>


          </> : null}

      
      </PageWrapper>

      </>
}