import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  useTheme, Button, Box, Typography
} from '@mui/material'
import FilterButton from 'components/FilterButton';
import constants from 'assets/constants'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    /*title: {
      display: true,
      text: 'Aantal actieve spelers',
    },*/
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  scales: {
    y: {
      
      display: false
    },
    x: {
      grid: {
        display: false,
      }
    }
  },
  bar: {
    borderRadius: 10,
  }
};

//const labels = ['P50', 'P100', 'P200', 'P300', 'P400', 'P500', 'P700', 'P1000'];
const labels = ['3', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100', '105', '110'];
const pyramids = constants["FILTERS"]["PYRAMID"]
export default function ExperimentalChart() {

  const theme = useTheme()

  const data = {
    labels,
    datasets: [
      {
        label: 'Mannen',
        data: [0,1,2,3,10,12,2,2,2,0,1,2,3,10,12,2,2,2,0,1,2,3,10,12,2,2,2,0,1,2,3,10,12,2,2,2],
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
      },
      {
        label: 'Vrouwen',
        data: [0,1,2,3,10,12,2,2,2,0,1,2,3,10,12,2,2,2,0,1,2,3,10,12,2,2,2,0,1,2,3,10,12,2,2,2],
        backgroundColor: '#afd9fa',
        borderRadius: 5,
      },
    ],
  
  };

  const [filter, setFilter] = React.useState(undefined);

  return <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", pt: 2}}>
    <Box display="flex" flexDirection="row" sx={{py: 1}}>

        <FilterButton variant="text" sx={{fontWeight: "bold", fontSize: 16, color: theme => theme.palette.text.primary_bold}} name="Actieve spelers" values={pyramids} value={filter} setValue={setFilter} />

        </Box>
    <Bar options={options} data={data} />
      
  </Box>;
}
