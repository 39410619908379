import * as React from 'react';
import {
  Popover, Box, Card, Typography, Link, CardActionArea, Button, useTheme, Divider
} from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchData, getRecentResults } from 'assets/utils';
import { WinIcon, LoseIcon, EmptyIcon } from 'assets/Icons';

export function RecentResults({favorites}) {
    const [recentResults, setRecentResults] = React.useState(null)
    const fetching = React.useRef(false);

    React.useEffect(() => {
        async function run () {
            if (favorites.length > 0 && fetching.current == false) {
                fetching.current = true
                const rc = await getRecentResults(favorites.map(el => el.userId))
                setRecentResults(rc)
                fetching.current = false
            }
        }
        
        run()
    }, [favorites])

    return recentResults !== null && recentResults["recent_results"].length > 0 ? <>
        <Typography variant="h6" fontWeight="bold" color="text.primary_bold" component="div" sx={{pt: 2}}>
        Recente resultaten
        </Typography>
        <Box sx={{display: "flex", alignItems: "flex-start", flexDirection: "column", mt: 0.5}}>
            {recentResults["recent_results"].map(el => <ResultLine key={el.favorites + el.tournament_id} result={el} users={recentResults["users"]}/>)}
        </Box>
        </> : null
}



function ResultLine({result, users}) {

    const navigate = useNavigate()
    const theme = useTheme()
    
    const navigateToUser = (userId) => fetchData(userId, null, (content) => {navigate("/speler/" + userId, {state: {...content, ts: new Date().getTime()}})})

    return <Box sx={{py: 1, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography variant="body2" component="div" sx={{pr: 0.5, fontWeight: "bold"}}>
                    {result["favorites"].map((el, index) =>
                    [
                    index > 0 ? <b style={{fontWeight: "bold", color: theme.palette.text.secondary}}>
                    &nbsp;•&nbsp; 
                    </b> : null,
                    <b style={{fontWeight: "bold", textTransform: "none", cursor:"pointer", color: theme.palette.primary.main}} onClick={() => navigateToUser(el)}>
                        {users[el].name}
                    </b>])}
                    <b style={{fontWeight: "bold", color: theme.palette.text.secondary}}>
                    &nbsp;•&nbsp;
                    </b>
                    <b style={{fontWeight: "bold", color: theme.palette.text.primary_bold}}>
                        {result.title}
                    </b>
                </Typography>
            </Box>
            <Typography variant="caption" component="div"  color="text.secondary" sx={{pb: 0.25}}>
                {result.subtitle}
            </Typography>
            <div style={{display: "flex", alignItems: "center"}}>

            {result["WL"].map((el, index) => el == "W" ? <WinIcon key={el + index}/> : (el == "L" ? <LoseIcon key={el + index}/> : <EmptyIcon key={el+index}/>))}
           
           
            </div>
    </Box>
}