import {
    LinearProgress, Typography, Stack, useTheme, Button
  } from '@mui/material'
import {disp_rank_short, next_rank, getAllRankings} from 'assets/utils';
import PercentageBar from 'components/PercentageBar2';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

function getMarks(data, firstMark, lastMark, theme) {
  const curRank = data["current_rank"];
  const predRank = data["predicted_rank"];
  const predNextRank = next_rank(predRank);


  var rank = firstMark;
  var marks = [];

  while (rank <= lastMark) {
    const label = disp_rank_short(rank, data["sub_category"]);
    const points = rank == predRank ? data["mi"] : (rank == predNextRank ? data["ma"] : null);
    const color = rank <= curRank ? theme.palette.primary.dark : theme.palette.primary.main;

    marks.push({
      rank: rank,
      value: getAllRankings().indexOf(rank),
      label: label, 
      points: points,
      color: color
    });
    rank = next_rank(rank);
  }

  return marks;
}

export default ({data, inverted, firstMark, lastMark}) => {

    const s = data ? data["score"] : 0;
    const ma = data ? data["ma"] : 0;
    const mi = data ? data["mi"] : 0;
    const percentage_unb = s == 0 ? 0 : (s-mi)/(ma-mi);
    const percentage = percentage_unb < 0 ? 0 : (percentage_unb > 1 ? 1 : percentage_unb);

    const theme = useTheme();
    const marks = getMarks(data, firstMark, lastMark, theme);

    return <PercentageBar 
            marks={marks}
            percentage={100 * percentage}
            currentValue={data ? getAllRankings().indexOf(data["current_rank"]) : 0}
            predictedValue={data ? getAllRankings().indexOf(data["predicted_rank"]) + percentage : 0}
        />
  
    return <Stack direction="row" alignItems="center">
      <div >
        <Typography sx={{ p: 2, pb: 0, textAlign: "center", fontWeight: "bold" }} color={inverted ? "common.white" : "primary"} component="div">{data ? disp_rank_short(data["predicted_rank"], data["sub_category"]) : ""}</Typography>
        <Typography sx={{ p: 2, pt: 0, textAlign: "center", fontWeight: "medium" }} variant="body2" color={inverted ? "common.white" : "primary"} component="div">{data ? data["mi"] : ""}</Typography>
      </div>
      
      <div>
        <LinearProgress sx={{width: 120, pt: 0.5, mt: 1}} variant="determinate" value={100 * percentage} />
        <Typography sx={{ textAlign: "center" }} variant="body2" color={inverted ? "common.white" : "primary"} component="div">{Math.round(100 * percentage)}%</Typography>
      </div>
  
      <div >
        <Typography sx={{ p: 2, pb: 0, textAlign: "center", fontWeight: "bold" }} color={inverted ? "common.white" : "primary"} component="div">{data ? disp_rank_short(next_rank(data["predicted_rank"], ), data["sub_category"]) : ""}</Typography>
        <Typography sx={{ p: 2, pt: 0, textAlign: "center", fontWeight: "medium" }} variant="body2" color={inverted ? "common.white" : "primary"} component="div">{data ? data["ma"] : ""}</Typography>
      </div>
    </Stack>
    
  }