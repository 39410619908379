function fnv1aHash(str) {
    let hash = 2166136261; // FNV-1a 32-bit offset basis
    for (let i = 0; i < str.length; i++) {
        hash ^= str.charCodeAt(i);
        hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
    }
    return hash >>> 0; // Convert to unsigned 32-bit integer
}

  function pastelColour(str){ 

    // Step 2: Normalize the hash to a number between 0 and 1
    const normalizedHash = fnv1aHash(str + "apqddve123121q") / 4294967296;

    return "hsl(" + 360 * normalizedHash + ',' +
               '70%,' + 
               '70%)'
}

export default function SeriesIcon({series, singles}) {
    const length = 15 + 5 * series.length;

    return <svg width={length} height="20" style={{marginRight: "5px"}}>
        <rect x="0" y="0" width={length} height="20" fill={pastelColour(singles ? "S" + series.substring(1) : "H" + series.substring(1))} rx={10} />
        <text x={length/2} y="11" font-size="10" fontWeight="bold" fill="white" text-anchor="middle" alignment-baseline="middle">{series}</text>
    </svg>
}