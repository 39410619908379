import * as React from 'react';
import {
    Card, TextField, CardContent, Box, IconButton, Grid, 
    Typography, Popover, Divider, CardActionArea, useTheme, useMediaQuery, Collapse
  } from '@mui/material'

import { disp_rank, disp_rank_short } from '../../../assets/utils'
import PercentageCard from 'pages/main/components/PercentageCard';


export default ({data, navigateToUser}) => {

    const theme = useTheme()

    const nr = data["nr"]
    const name=data["name"]
    const club=data["club"]
    const single=data["singles"]["current_rank"]
    const single_pred=data["singles"]["predicted_rank"]
    const single_sub=data["singles"]["sub_category"]

    const double=data["doubles"]["current_rank"]
    const double_pred=data["doubles"]["predicted_rank"]
    const double_sub=data["doubles"]["sub_category"]

    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [collapse, setCollapse] = React.useState(false);

    return <Card sx={{borderRadius: 2, mx: 1, border:"0px solid lightgrey"}} elevation={0}>
        <CardActionArea onClick={()=>{navigateToUser(data["id"])}} disableRipple>
        <Box sx={{display: "flex", p: 1, px: 1}} justifyContent="space-between" alignItems="center">
            <div>
            <Typography fontSize={14} fontWeight="bold" color="primary" component="div">
                {nr}. {name}
            </Typography>
            <Typography fontSize={12} component="div" color="text.primary">
                {club}
            </Typography>
            </div>
            <div>
                <RankLine tag={smallScreen ? "ENK" : "ENKEL"} rank={single} rank_pred={single_pred} category={single_sub}/>
                <RankLine tag={smallScreen ? "DBL" : "DUBBEL"} rank={double} rank_pred={double_pred} category={double_sub}/>
            </div>
        </Box>
        </CardActionArea>


        <Collapse in={collapse} unmountOnExit>
        <CardContent>
        <Typography variant="body" fontSize={14} component="div" color="text.primary_bold">
            Huidig enkelklassement: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary">{disp_rank(single, single_sub)}</Typography>
        </Typography>
        
        <Typography variant="body" fontSize={14} component="div" color="text.primary_bold">
            Huidig dubbelklassement: <Typography variant="body" sx={{fontWeight: "bold"}} color="primary">{disp_rank(double, double_sub)}</Typography>
        </Typography>
       

        <Card sx={{mt: 2, border: (theme) => "1px solid " + theme.palette.primary.main}} elevation={0}>
            <Box sx={{pl: 2, backgroundColor: "primary.light", height: 60, display: "flex"}} flexDirection="column" justifyContent="center">
                <Typography variant="body" color="common.white" sx={{fontWeight: "bold"}}>
                    Voorspelling enkel: {disp_rank(single_pred, single_sub)}
                </Typography>
                <Typography variant="caption" color="common.white" sx={{fontWeight: "regular", mb: 0.5}}>
                    Huidige klassementscore: {data["singles"]["score"]}
                </Typography>
            </Box>
            <Box sx={{display: "flex", justifyContent: "center"}}>
            <PercentageCard data={data["singles"]}/>
            </Box>
        </Card>

        <Card sx={{mt: 2, border: (theme) => "1px solid " + theme.palette.primary.main}} elevation={0}>
            <Box sx={{pl: 2, backgroundColor: "primary.light", height: 60, display: "flex"}} flexDirection="column" justifyContent="center">
                <Typography variant="body" color="common.white" sx={{fontWeight: "bold"}}>
                    Voorspelling dubbel: {disp_rank(double_pred, double_sub)}
                </Typography>
                <Typography variant="caption" color="common.white" sx={{fontWeight: "regular", mb: 0.5}}>
                    Huidige klassementscore: {data["doubles"]["score"]}
                </Typography>
            </Box>
            <Box sx={{display: "flex", justifyContent: "center"}}>
            <PercentageCard data={data["doubles"]}/>
            </Box>
        </Card>
        </CardContent>
        </Collapse>
    </Card>
}

const RankLine = ({tag, rank, rank_pred, category}) => <Typography fontSize={14} color="text.primary_bold" component="div" textAlign="right" sx={{minWidth: 160}}>
        {tag} <Typography display="inline-block" sx={{width: 110, ml: 1, textAlign: "left", whiteSpace: "nowrap"}} fontWeight={"bold"} fontSize={14} color="primary" component="div">{disp_rank_short(rank, category)}{rank != rank_pred ? <><Typography display="inline" color="text.primary_bold"> &#8702; </Typography> {disp_rank_short(rank_pred, category)}</> : ""}</Typography>
</Typography>