import * as React from 'react';
import {
  Card, Button, Typography, Collapse, CardContent, Select, MenuItem, Box, CardActionArea, Grid
} from '@mui/material'
import constants from 'assets/constants';
import { useInViewEffect } from 'react-hook-inview'
import { disp_rank, useFirstRender } from 'assets/utils';
import PercentageCard from 'pages/main/components/PercentageCard';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Adsense} from '@ctrl/react-adsense'
import PageWrapper from 'components/PageWrapper'
import {useNavigate} from 'react-router-dom'
import {scrollTo, fetchData} from 'assets/utils'
import FilterButton from 'components/FilterButton'

async function fetchLeaderboard(s, from_i, to_i, filters) {
    let url = constants["BACKEND"] + "/get_leaderboard/" + s + "?from=" + from_i + "&to=" + to_i

    if (filters["category"] !== undefined) {
        url = url + "&category=" + pyramids[filters["category"]]
    }
    if (filters["rank"] !== undefined) {
        url = url + "&rank=" + ranks[filters["rank"]]
    }

    const data = await fetch(url);
    const content = await data.json();
    
    return content;
}

const pyramids = constants["FILTERS"]["PYRAMID"]
const ranks = constants["FILTERS"]["RANK"]

const filterData = {
    "category": {"values": pyramids, "name":"Piramide"},
    "rank": {"values": ranks, "name":"Klassement"},
}

const tabs = constants["LEADERBOARD"]

export default function LeaderboardPage() {
    const [tab, setTab] = React.useState(0);
    const [maxRender, setMaxRender] = React.useState(100);
    const [content, setContent] = React.useState([]);
    const navigate = useNavigate();
    const fetching = React.useRef(false);

    const [filters, setFilters] = React.useState({})

    const handleTab = async (newValue) => {
        setTab(newValue);
        setMaxRender(100);
        setFilters({})

        fetching.current = true;
        const content = await fetchLeaderboard(tabs[newValue]["tag"], 0, 100, {});
        setContent(content);
        
    };

    const handleFilter = async (f) => {
        setMaxRender(100);

        fetching.current = true;
        const content = await fetchLeaderboard(tabs[tab]["tag"], 0, 100, f);
        setContent(content);
    };

    React.useEffect(() => {fetching.current = false;}, [content])
    
    const firstRender = React.useMemo(()=> {
        if ("sy" in window.history.state) {
            setTab(window.history.state["tab"])
            setContent(window.history.state["content"])
            setMaxRender(window.history.state["maxRender"])

            setTimeout(() => {
                scrollTo(window.history.state["sy"], ()=>{window.history.pushState({}, "");})
            })
        } else {
            handleTab(tab);
        }
    }, []);

    async function navigateToUser(userId) {
        window.history.pushState({"content": content, "tab": tab, "maxRender": maxRender, "sy": window.scrollY}, "");
        scrollTo(0, () => {
            fetchData(userId, null, (content) => {navigate("/speler/" + userId, {state: {...content, ts: new Date().getTime()}})})
          });
      }

    const ref = useInViewEffect(
        async ([entry], observer) => {
          if (entry.isIntersecting && fetching.current == false && content.length == maxRender) {
            observer.unobserve(entry.target)
            
            fetching.current = true;
            const new_content = await fetchLeaderboard(tabs[tab]["tag"], maxRender, maxRender + 100, filters);
            setContent(content.concat(new_content));
            setMaxRender(maxRender + 100);
            
          }
        },
        { threshold: 0, rootMargin: '2000px' }, [maxRender, content, setContent, tab]
      )
  
    return <PageWrapper sx={{px: 0}}>      

          <Box position="relative" sx={{mt: '50px'}}>   
            <Card elevation={0} sx={{
                pt: 3, pb: 3.5, px: 3, 
                borderRadius: 10, 
                border:  (theme) => '20px solid ' + theme.palette.primary.main,
                
                position: 'absolute',
                transform: 'translate(0, calc(-100% + -30px))',
                width: "100%"
                }}>
            {tabs.map((tabItem, tabId) => <Button 
                    key={tabId} 
                    sx={{borderBottomLeftRadius: 0, 
                        borderBottomRightRadius: 0, 
                        borderBottom: tabId == tab ? 2 : 0,
                        borderBottomColor: "primary",
                        color: tabId == tab ? "primary" : "text.secondary",
                    }}
                        
                    onClick={() => {handleTab(tabId)}}
                    >
                        {tabs[tabId]["name_short"]}
                    </Button>)}
                
            </Card>
            </Box>  

          <Card sx={{borderRadius: 2, mt: 2, mb: 1, mx: 1}} elevation={0}>
                <Box backgroundColor="primary.main" sx={{py: 2, px: 2}}>
                    <Typography color="common.white" variant="body" fontWeight="bold">{tabs[tab]["name"]}</Typography>
                </Box>
          </Card>
         
         {tabs[tab]["tag"].indexOf("club") >= 0 ? null :
          <Box sx={{mx: 0.5, mb: 1, display: 'flex', justifyContent: "flex-end"}}>
            {Object.keys(filterData).map((filter) => {
                const {name, values} = filterData[filter];
                return <FilterButton key={filter} value={filters[filter]} values={values} setValue={(v) => {
                    filters[filter] = v;
                    setFilters({...filters});
                    handleFilter(filters);
                }
                } name={name} />
            })}
          </Box>}

          <Card sx={{borderRadius: 2, mt: 2, mb: 2, mx: 1}} elevation={0}>
                {content.filter((item, index) => index < maxRender).map((item, index) => <React.Fragment key = {index+ "/" +item["id"]}>
                
                {tabs[tab]["lb_type"] == 0 ? 
                <InteractiveUserLeaderboardLine navigateToUser={navigateToUser} rank={index+1} id={item["id"]} title={item["title"]} subtitle={item["subtitle"]} value={item["value"]} tennisRank={item["r"]} /> : 
                <LeaderboardLine rank={index+1} id={item["id"]} title={item["title"]} subtitle={item["subtitle"]} value={item["value"]} />}

                </React.Fragment>)}

                {content.length == maxRender ? 
                <CardActionArea onClick={()=>{setMaxRender(maxRender + 50)}} sx={{pl: 2, pt: 1, pb: 1, pr: 2, justifyContent: "center"}}>
                <Grid ref={ref} container direction="row" alignItems="center" justifyContent="center">
                    <MoreHorizIcon color="text.primary" opacity={0.5}/>
                </Grid>
                    
                </CardActionArea> : null}
          </Card>

  
    </PageWrapper>
}

const InteractiveUserLeaderboardLine = ({rank, navigateToUser, id, title, subtitle, value, opacity, dense, tennisRank}) => {
    const [collapse, setCollapse] = React.useState(false);

    return <><CardActionArea sx={{borderRadius: 2}} disabled={id === 'nan'} onClick={() => navigateToUser(id)} disableRipple>
    <LeaderboardLine tennisRank={tennisRank} rank={rank} id={id} title={title} subtitle={subtitle} value={value} opacity={opacity} dense={dense}/>
    </CardActionArea>
</>}

export const LeaderboardLine = ({rank, tennisRank, title, subtitle, value, opacity, dense}) => {

    return <Box sx={{py: dense ? 1 : 2, px: 2}} display="flex" justifyContent="space-between">
        <Box sx={{opacity: opacity}}>
            <Typography color="primary" fontSize={16} fontWeight="bold">{rank}. {title}</Typography>
            <Typography component="div" color="text.primary_bold" variant="caption">{tennisRank == undefined ? null : <b>{tennisRank} &#8226;</b>} {subtitle}</Typography>
        </Box>
        <Box sx={{opacity: opacity}} alignSelf="center">
            <Typography fontSize={16} sx={{fontWeight: "bold", textAlign:"right"}} color="primary">
            {value}
            </Typography>
        </Box>
        </Box>
}